import { factory } from './app';
import { NO_CACHE } from '../utils/constants';

factory('LeiService', [
  '$rootScope',
  '$http',
  '$q',
  'Upload',
  function($rootScope, $http, $q, Upload) {
    let userSubmitData, transferData;

    return {
      getMergedPayloadSchema,
      getFullSchema,
      get: getFromSource.bind(null, undefined),
      getFromSource,
      getGleifLei,
      getLeiForForm,
      getPublicLeiDataFromAllSource,
      new: publish,
      challenge,
      update,
      renew,
      transfer,
      transferRenew,
      viewLeiChallenge,
      uploadAndValidateExcel,
      bulkNew,

      getBulkExcelTemplate,
      bulkRenew,
      bulkUpdate,
      bulkTransfer,
      bulkTransferRenew,
      validateExcelForUpdateRenew,
      validateExcelForTransfer,
      validateFormSection,
      getLeiXml,
      leiPayLoad,
      transferInfo,
      handleExportForTransfer,
      getEventIds
    };

    function handleExportForTransfer(ids) {
      var options = {
        method: 'POST',
        responseType: 'arraybuffer',
        url: '/lei/exportGleifExcelBulkViaPost',
        data: { ids }
      };
      return $http(options);
    }

    function leiPayLoad(val) {
      if (arguments.length === 1) {
        userSubmitData = val;
      } else return userSubmitData;
    }

    function transferInfo(val) {
      if (arguments.length === 1) {
        transferData = val;
      } else return transferData;
    }

    function getLeiXml() {
      var options = {
        method: 'GET',
        url: '/lei/xml'
      };

      return $http(options);
    }

    function getMergedPayloadSchema(leiId, action, isGleif) {
      var expandedInput = getFullSchema().then(function(res) {
        return res && res.data;
      });

      var bbdsData = (isGleif ? getGleifLei : getLeiForForm)(leiId, action).then(function(res) {
        return res && res.data;
      });

      return $q.all([expandedInput, bbdsData]);
    }

    /**
     * get Lei full schema from backend
     */
    function getFullSchema() {
      var options = {
        method: 'GET',
        url: '/lei/gdw-ext/full'
      };

      return $http(options);
    }

    // getLei for update/renew/view
    function getLeiForForm(leiId, action) {
      return $http.get(['/lei', leiId, action].join('/'));
    }

    function getPublicLeiDataFromAllSource(leiId) {
      return $http.get(['/lei', 'anyLei', leiId].join('/'));
    }

    /**
     *
     * @param {string} 1, 3 - BB LEI, 2, others - GLEIF, when omitted defaults to GLEIF
     * @param {string} leiId
     * @returns {*|{}}
     */
    function getFromSource(
      sourceId,
      leiId,
      _noAuthorize,
      _fields,
      _offset,
      _limit,
      _sort,
      filter,
      numFilter,
      namedFilter,
      enrich = false,
      extraParams
    ) {
      const params = {
        leiId: leiId,
        _noAuthorize: _noAuthorize,
        _fields: _fields,
        _offset: _offset,
        _limit: _limit,
        _sort: _sort,
        filter: filter,
        numFilter: numFilter,
        namedFilter: namedFilter,
        sourceId: sourceId,
        enrich,
        ...extraParams
      };

      $rootScope.$emit('page-fetch', Object.assign({}, params));

      const options = {
        method: 'GET',
        url: '/lei/' + encodeURI(leiId || ''),
        headers: NO_CACHE,
        params: params
      };

      return $http(options);
    }

    // get otherLOU's lei data
    function getGleifLei(gleifId, action) {
      action = action || 'view';
      if (action.toLowerCase() === 'view') {
        return $http.get(`/gleifs/view/${gleifId}`);
      }
      return $http.get('/lei/gleif/' + gleifId + '/' + action);
    }

    function transfer(processedPayload, customerConsent, leiId, options, files) {
      return Upload.upload({
        method: 'POST',
        url: '/lei/transfer/' + leiId,
        data: {
          files: files,
          processedPayload: JSON.stringify(processedPayload),
          customerConsent: JSON.stringify(customerConsent),
          options: JSON.stringify(options)
        }
      });
    }

    function transferRenew(processedPayload, customerConsent, leiId, options, files) {
      return Upload.upload({
        method: 'POST',
        url: '/lei/transfer-renew/' + leiId,
        data: {
          files: files,
          processedPayload: JSON.stringify(processedPayload),
          customerConsent: JSON.stringify(customerConsent),
          options: JSON.stringify(options)
        }
      });
    }

    /**
     * @param processedPayload - JSON String
     * @param files - attachments
     * action to PUT form e.g. LEI update form
     */
    function update(processedPayload, customerAgreement, leiId, options, files) {
      return Upload.upload({
        method: 'PUT',
        url: '/lei/' + leiId,
        data: {
          files: files,
          processedPayload: JSON.stringify(processedPayload),
          customerAgreement: JSON.stringify(customerAgreement),
          options: JSON.stringify(options)
        }
      });
    }

    /**
     * @param processedPayload - JSON String
     * @param files - attachments
     * action to POST form e.g. LEI renew form
     */
    function renew(processedPayload, customerAgreement, leiId, options, files) {
      return Upload.upload({
        url: '/lei/renew/' + leiId,
        data: {
          files: files,
          processedPayload: JSON.stringify(processedPayload),
          customerAgreement: JSON.stringify(customerAgreement),
          options: JSON.stringify(options)
        }
      });
    }

    function challenge(processedPayload, leiId) {
      var options = {
        method: 'POST',
        url: '/lei/challenge/' + leiId,
        data: {
          processedPayload: processedPayload
        }
      };

      return $http(options);
    }

    function viewLeiChallenge(id) {
      return $http.get('/lei/challenge/' + id);
    }

    function validateFormSection(action, leiId, goldenPayload, processedPayload, sectionId, skipWarnings) {
      const options = {
        method: 'POST',
        url: '/lei/validateSection',
        data: {
          processedPayload: JSON.stringify(processedPayload),
          goldenPayload: JSON.stringify(goldenPayload || '{}'),
          leiId,
          sectionId,
          action,
          skipWarnings
        }
      };
      return $http(options);
    }

    /**
     * @param processedPayload - JSON String
     * @param files - attachments
     * action to POST form e.g. LEI form
     */
    function publish(processedPayload, customerAgreement, _undef_, options, files) {
      return Upload.upload({
        url: '/lei',
        data: {
          files: files,
          processedPayload: JSON.stringify(processedPayload),
          customerAgreement: JSON.stringify(customerAgreement),
          options: JSON.stringify(options)
        }
      });
    }

    /**
     * @param file - an excel file
     * action to validation LEI data in one excel file
     */
    function uploadAndValidateExcel(file) {
      return Upload.upload({
        url: '/lei/uploadAndValidateExcel',
        data: { file: file }
      });
    }

    /**
     * @param file - an excel file
     * action to validation LEI data in one excel file
     * for update and renew
     */
    function validateExcelForUpdateRenew(file) {
      return Upload.upload({
        url: '/lei/validateExcelForUpdateRenew',
        data: { file: file }
      });
    }

    /**
     * @param file - an excel file
     * action to validation LEI data in one excel file
     * for transfer and transfer_renew
     */
    function validateExcelForTransfer(file) {
      return Upload.upload({
        url: '/lei/validateExcelForTransfer',
        data: { file: file }
      });
    }

    /**
     * @param dataPartArray - JSON String contains list of LEIs, data part only
     * @param files - attachments
     * action to POST json payload form e.g. LEI
     */
    function bulkNew(dataPartArrayString, customerAgreement, files) {
      return Upload.upload({
        url: '/lei/publish-bulk',
        data: { files: files, dataPartArray: dataPartArrayString, customerAgreement: customerAgreement }
      });
    }

    /**
     * @param dataPartArray - JSON String contains list of LEIs, data part only
     * @param files - attachments
     * action to POST json payload form e.g. LEI
     */
    function bulkRenew(dataPartArrayString, customerAgreement, files) {
      return Upload.upload({
        url: '/lei/bulkRenew',
        data: { files: files, dataPartArray: dataPartArrayString, customerAgreement: customerAgreement }
      });
    }

    /**
     * @param dataPartArray - JSON String contains list of LEIs, data part only
     * @param files - attachments
     * action to POST json payload form e.g. LEI
     */
    function bulkUpdate(dataPartArrayString, customerAgreement, files) {
      return Upload.upload({
        url: '/lei/bulkUpdate',
        data: { files: files, dataPartArray: dataPartArrayString, customerAgreement: customerAgreement }
      });
    }

    /**
     * @param dataPartArray - JSON String contains list of LEIs, data part only
     * @param files - attachments
     * action to POST json payload form e.g. LEI
     */
    function bulkTransfer(dataPartArrayString, customerActions, files) {
      return Upload.upload({
        url: '/lei/bulkTransfer',
        data: { files: files, dataPartArray: dataPartArrayString, customerActions: customerActions }
      });
    }

    /**
     * @param dataPartArray - JSON String contains list of LEIs, data part only
     * @param files - attachments
     * action to POST json payload form e.g. LEI
     */
    function bulkTransferRenew(dataPartArrayString, customerActions, files) {
      return Upload.upload({
        url: '/lei/bulkTransferRenew',
        data: { files: files, dataPartArray: dataPartArrayString, customerActions: customerActions }
      });
    }

    function getBulkExcelTemplate() {
      var options = {
        method: 'GET',
        url: '/lei/excelTemplate'
      };

      return $http(options);
    }

    /**
     * @param getEventIds - JSON String
     * @param numIds - number of ids to generate
     */
    function getEventIds(numIds) {
      const options = {
        method: 'GET',
        url: '/lei/eventIds/' + numIds
      };
      return $http(options);
    }
  }
]);
