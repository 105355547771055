import angular from 'angular';
import { checkStore, schemaStore } from '../../../../common/validate';
import { controller, factory } from '../app';

var assign = angular.extend;

factory('LoginStore', [
  'ReduxStoreFactory',
  'LoginReducer',
  function(ReduxStoreFactory, LoginReducer) {
    var initialState = {
      model: {
        username: null,
        password: null,
        email: null,
        twoFactorCode: null
      },
      validate: {},
      ui: {
        showAllErrors: false,
        oneFactorValidated: false
      }
    };

    return ReduxStoreFactory('LoginStore', LoginReducer, initialState);
  }
]);

factory('LoginActionType', [
  'ReduxActionTypeFactory',
  function(ReduxActionTypeFactory) {
    return ReduxActionTypeFactory(['UPDATE', 'ONE_FACTOR_VALIDATED']);
  }
]);

factory('LoginAction', [
  'LoginActionType',
  'ReduxUIAction',
  function(LoginActionType, ReduxUIAction) {
    return assign({}, ReduxUIAction, {
      update: function(user) {
        return {
          type: LoginActionType.UPDATE,
          data: user
        };
      },
      oneFactorValidated: function(validatedOrNot) {
        return {
          type: LoginActionType.ONE_FACTOR_VALIDATED,
          data: validatedOrNot
        };
      }
    });
  }
]);

factory('LoginReducer', [
  'ReduxUIReducer',
  'ReduxReducerFactory',
  'LoginActionType',
  function(ReduxUIReducer, ReduxReducerFactory, LoginActionType) {
    var modelReducer = ReduxReducerFactory.assign(LoginActionType.UPDATE);

    var oneFactorValidatedReducer = ReduxReducerFactory.value(LoginActionType.ONE_FACTOR_VALIDATED);

    return function r(state, action) {
      return {
        model: modelReducer(state.model, action),
        validate: ReduxUIReducer.validate(state.validate, action),
        ui: {
          showAllErrors: ReduxUIReducer.showAllErrors(state.ui.showAllErrors, action),
          oneFactorValidated: oneFactorValidatedReducer(state.ui.oneFactorValidated, action)
        }
      };
    };
  }
]);

controller('LoginController', [
  'UserService',
  'ServiceErrorHandler',
  'LoginStore',
  'LoginAction',
  'Redirect',
  '$route',
  '_accessLevel',
  'AlertMessenger',
  'recaptchaResult',
  'Alert',
  'recaptchaFactory',
  function(
    UserService,
    ServiceErrorHandler,
    LoginStore,
    LoginAction,
    Redirect,
    $route,
    _accessLevel,
    AlertMessenger,
    recaptchaResult,
    Alert,
    recaptchaFactory
  ) {
    if (_accessLevel && _accessLevel > 1) {
      Redirect.index();
      return;
    }

    AlertMessenger.info(
      'For any users whose LEIs are being transferred from GMEI/DTCC during the August 2023 transition, please visit this FAQ '
    );

    var checkLogin = checkStore.get('login');
    var checkTwoFactorCode = checkStore.get('twoFactorCode');

    var self = this;

    self.props = {
      recaptchaResult,
      recaptchaResponse: null
    };
    self.props.loginSchema = schemaStore.get('login');
    self.props.twoFactorCodeSchema = schemaStore.get('twoFactorCode');

    self.state = angular.extend({}, LoginStore.getState(), {
      vm: {}
    });

    self.props.recaptcha_protected = someFunction =>
      recaptchaFactory.recaptcha_protected(someFunction, self.props, Alert);

    function updateSelfState(state) {
      self.state.model = state.model;
      self.state.validate = state.validate;
      self.state.ui = state.ui;
    }

    LoginStore.reset().subscribe(updateSelfState);

    self.props.reset = function reset() {
      self.state.vm = {};

      LoginStore.reset();

      var resetState = LoginStore.getState();
      updateSelfState(resetState);
    };

    self.props.handleLoginChange = function handleLoginChange() {
      var input = {
        username: self.state.vm.username,
        password: self.state.vm.password,
        email: self.state.vm.email
      };
      var check = checkLogin(input);

      LoginStore.dispatch(LoginAction.validate(check));

      if (!check.$valid) {
        return;
      }

      var updateAction = LoginAction.update(self.state.vm);

      LoginStore.dispatch(updateAction);
    };

    self.props.handleTwoFactorCodeChange = function handleTwoFactorCodeChange() {
      self.state.vm.twoFactorCode = (self.state.vm.twoFactorCode || '').toUpperCase();

      var input = {
        twoFactorCode: self.state.vm.twoFactorCode
      };

      var check = checkTwoFactorCode(input);

      LoginStore.dispatch(LoginAction.validate(check));

      if (!check.$valid) {
        return;
      }

      var updateAction = LoginAction.update(self.state.vm);

      LoginStore.dispatch(updateAction);
    };

    self.props.handleValidateUserSubmit = function handleValidateUserSubmit() {
      var model = LoginStore.getState().model;

      return UserService.validateUser(model.username, model.password, model.email, self.props.recaptchaResponse)
        .then(function() {
          LoginStore.dispatch(LoginAction.oneFactorValidated(true));
        })
        .catch(ServiceErrorHandler({ alert: 'data' }))
        .catch(function handleUnlockedAccount(rejection) {
          if (rejection.status === 423) {
            Redirect.unlockAccount(model.username, model.email);
          }
        });
    };

    self.props.handleTwoFactorCodeSubmit = function handleTwoFactorCodeSubmit() {
      var twoFactorCode = LoginStore.getState().model.twoFactorCode;

      return UserService.validateTwoFactorCode(twoFactorCode)
        .then(function() {
          Redirect.indexAfterLogin();
        })
        .catch(ServiceErrorHandler({ alert: 'data' }))
        .catch(function(rejection) {
          if (rejection.status === 408) {
            $route.reload();
          }
        });
    };
  }
]);
