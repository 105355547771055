import angular from 'angular';
import { directive, factory } from './app';

directive('recaptcha', [
  '$window',
  function($window) {
    return {
      restrict: 'E',
      scope: {
        siteKey: '@'
      },
      link: function(scope, element, attrs) {
        scope.$watch('siteKey', function(val) {
          if (!val) {
            return;
          }

          injectRecaptcha(val);
        });

        function injectRecaptcha(val) {
          var recaptchaScript = document.createElement('script');
          recaptchaScript.setAttribute('src', 'https://www.google.com/recaptcha/api.js?render=' + val);
          element.append(angular.element(recaptchaScript));
        }
      }
    };
  }
]);

async function recaptcha_protected(someFunction, props, Alert, { $window, $timeout }) {
  let grecaptcha = $window.grecaptcha;

  if (!grecaptcha) {
    // needed to allow recaptcha injection to complete
    grecaptcha = await $timeout(
      () => {
        return $window.grecaptcha;
      },
      500 //delay
    );

    if (!grecaptcha) return Alert.error('recaptchaRefresh');
  }

  return grecaptcha.ready(function() {
    return grecaptcha
      .execute(props.recaptchaResult.captchaPublicKey, { action: someFunction.name })
      .then(function(token) {
        props.recaptchaResponse = token;
        return someFunction();
      });
  });
}

factory('recaptchaFactory', [
  '$window',
  '$timeout',
  function($window, $timeout) {
    return {
      recaptcha_protected: (someFunction, props, Alert) =>
        recaptcha_protected(someFunction, props, Alert, { $window, $timeout })
    };
  }
]);
